import classNames from "classnames"
import React from "react"

type Props = {
  className?: string
  alt?: string
}

const Logo: React.FC<Props> = ({ className, alt }) => {
  return (
    <svg
      width="110"
      height="42"
      viewBox="0 0 110 42"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("group", className)}
      aria-labelledby={alt || ""}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0501 20.859C36.0501 24.1834 38.0228 25.8 41.3299 25.8C44.6371 25.8 46.6677 24.3665 46.6677 20.859C46.6677 17.26 44.6371 15.7044 41.3588 15.7044C38.0516 15.7044 36.0501 17.3516 36.0501 20.8286V20.859ZM41.4202 8.52521C43.9892 8.52521 46.2829 9.01106 48.2403 9.9504C52.2163 11.8936 54.5714 15.5539 54.5714 20.7686V20.898C54.5714 23.6187 54.0209 25.8536 52.9197 27.6676C50.7179 31.2627 46.6806 32.9796 41.3588 32.9796C38.6676 32.9796 36.3431 32.5584 34.3244 31.6838C30.3484 29.9348 28.1463 26.4368 28.1463 20.8659V20.8009C28.1463 18.2096 28.7275 16.0071 29.8285 14.1934C32.0918 10.501 36.1596 8.52521 41.4202 8.52521ZM16.8328 17.8134L22.1056 9.10827H30.861L20.706 25.7573V32.3318H12.6667V25.7247L3 9.10827H11.7877L16.8328 17.8134ZM92.7579 25.8C89.4505 25.8 87.478 24.1834 87.478 20.859V20.8286C87.478 17.3516 89.4796 15.7044 92.7867 15.7044C96.0648 15.7044 98.0957 17.26 98.0957 20.859C98.0957 24.3665 96.0648 25.8 92.7579 25.8ZM99.6684 9.9504C97.711 9.01106 95.4171 8.52521 92.8481 8.52521C87.5877 8.52521 83.5199 10.501 81.2566 14.1934C80.1554 16.0071 79.5744 18.2096 79.5744 20.8009V20.8659C79.5744 26.4368 81.7763 29.9348 85.7525 31.6838C87.7709 32.5584 90.0955 32.9796 92.7869 32.9796C98.1085 32.9796 102.146 31.2627 104.348 27.6676C105.449 25.8536 106 23.6187 106 20.898V20.7686C106 15.5539 103.644 11.8936 99.6684 9.9504ZM57.8257 14.1934C60.089 10.501 64.1567 8.52521 69.4173 8.52521C71.9863 8.52521 74.2802 9.01106 76.2374 9.9504C76.871 10.26 77.4629 10.6136 78.0111 11.0098V18.3596C78.0111 18.3596 73.997 15.7045 70.4496 15.7045C67.1422 15.7045 64.4844 17.3516 64.4844 20.8287V20.8593C64.4844 24.1837 67.1133 25.8 70.4205 25.8C74.051 25.8 78.0111 23.1453 78.0111 23.1453V30.7119C75.781 32.2355 72.8475 32.9796 69.3561 32.9796C66.6646 32.9796 64.3401 32.5587 62.3217 31.684C58.3457 29.935 56.1436 26.4368 56.1436 20.8659V20.8009C56.1436 18.2099 56.7248 16.0074 57.8257 14.1934Z"
      />
    </svg>
  )
}

export default Logo
