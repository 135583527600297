import debounce from "lodash/debounce"
import { useEffect, useState } from "react"

import theme from "@theme"

export const getPxUnit = (pxValue: string) =>
  parseInt(pxValue.replace("px", ""))

export const isMobile = () => {
  if (typeof document !== "undefined") {
    return document.body.clientWidth < getPxUnit(theme.screens["sm-v2"])
  }

  return false
}

export const isTablet = () => {
  if (typeof document !== "undefined") {
    return document.body.clientWidth >= getPxUnit(theme.screens["sm-v2"])
  }

  return false
}

export const isLaptop = () => {
  if (typeof document !== "undefined") {
    return document.body.clientWidth >= getPxUnit(theme.screens["md-v2"])
  }

  return false
}

export const isDesktop = () => {
  if (typeof document !== "undefined") {
    return document.body.clientWidth >= getPxUnit(theme.screens["lg-v2"])
  }

  return false
}

const breakpointUtils = {
  mobile: isMobile,
  tablet: isTablet,
  laptop: isLaptop,
  desktop: isDesktop,
}

/**
 * Reactively check whether breakpoint has been reached.
 *
 * Use by passing in one of our V2 screen utils.
 *
 * @example From a component:
 * ```tsx
 * const isMobile = useIsBreakpointCheck(isDesktopCheck)
 * ```
 */
export const useIsBreakpoint = (
  utilKey: keyof typeof breakpointUtils
): boolean => {
  const [isBreakpoint, setIsBreakpoint] = useState(true)

  useEffect(() => {
    const windowResized = debounce(
      () => {
        setIsBreakpoint(breakpointUtils[utilKey])
      },
      500,
      { trailing: true }
    )

    window.addEventListener("resize", windowResized)

    setIsBreakpoint(breakpointUtils[utilKey])

    return () => {
      window.removeEventListener("resize", windowResized)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isBreakpoint
}
