import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import { getGatsbyImage } from "../../../../plugins/storyblok-image-plugin/src"
import { getImageDimensionsFromUrl } from "../../../utils/image"

type Props = {
  image: string
  alt: string
  width?: number
  loadType?: "eager" | "lazy"
  quality?: number
  rounded?: boolean
  aspectRatio?: "1:1" | "4:3" | "3:4" | "16:9" | "fullWidth"
  className?: string
  imageObjectFit?: "contain" | "cover"
}

const GatsbyStoryblokImage: React.FC<Props> = ({
  image,
  alt = "",
  width,
  loadType = "lazy",
  quality = undefined,
  rounded = false,
  aspectRatio = "4:3",
  className,
  imageObjectFit = "cover",
}) => {
  const ASPECT_RATIO =
    aspectRatio !== "fullWidth"
      ? parseInt(aspectRatio.split(":")[1]) /
        parseInt(aspectRatio.split(":")[0])
      : undefined

  if (image.includes(".svg")) {
    return (
      <img
        loading={"lazy"}
        className={classNames(
          { "rounded overflow-hidden translate-x-0": rounded },
          "max-w-full h-auto",
          className
        )}
        src={image}
        alt={alt}
        width={width}
      />
    )
  } else {
    const WIDTH =
      width || parseInt(getImageDimensionsFromUrl(image).width ?? "0")

    const imageProps = getGatsbyImage(image, {
      width: WIDTH,
      height: WIDTH && ASPECT_RATIO && WIDTH * ASPECT_RATIO,
      layout: aspectRatio === "fullWidth" ? "fullWidth" : "constrained",
      quality,
      aspectRatio: ASPECT_RATIO,
    })

    return (
      <GatsbyImage
        image={imageProps}
        alt={alt}
        loading={loadType}
        className={classNames(
          { "rounded overflow-hidden translate-x-0": rounded },
          "max-w-full",
          className
        )}
        imgStyle={{ objectFit: imageObjectFit }}
      />
    )
  }
}

export default GatsbyStoryblokImage
