import { Link as GatsbyLink } from "gatsby"
import React, { useMemo } from "react"

import { linkIsExternalUrl, getUrlFromStoryblokLink } from "@utils/storyblok"

type Props = React.HTMLAttributes<HTMLAnchorElement> & {
  children: any
  link: string | Storyblok.Link
  opensNewTab?: boolean
}

/*
  Usage note: This Link element should be used to augment
  another element or component to turn it into a link where
  we cannot predict whether the link target of the link will
  be internal or external. E.g. an image or custom card may be
  wrapped in this Link.
*/
const Link = React.forwardRef<HTMLElement, Props>(
  ({ children, link, opensNewTab = true, ...props }, ref) => {
    const href = useMemo(() => {
      if (link) {
        if (typeof link === "string") {
          return link
        } else {
          return getUrlFromStoryblokLink(link)
        }
      }

      return undefined
    }, [link])

    if (linkIsExternalUrl(link)) {
      if (opensNewTab) {
        return (
          <a
            href={href}
            target="_blank"
            rel="noreferrer nofollow"
            ref={ref as React.Ref<HTMLAnchorElement>}
            {...props}
          >
            {children}
          </a>
        )
      } else {
        return (
          <a href={href} ref={ref as React.Ref<HTMLAnchorElement>} {...props}>
            {children}
          </a>
        )
      }
    }

    return (
      // TODO - fix any type
      <GatsbyLink to={href || "/"} ref={ref as React.Ref<any>} {...props}>
        {children}
      </GatsbyLink>
    )
  }
)

Link.displayName = "Link"

export default React.memo(Link)
