// TODO: remove if possible and use new v2 CloseIcon instead
import React from "react"

import { Color } from "../../../constants/V2/color"
import { getTextColorV1Class } from "../../../utils/color"
import {
  getStrokeColorClass,
  getTextColorClass as getTextColorClassV2,
} from "../../../utils/V2/color"

type Props = {
  color?: Storyblok.ColorV1 | Color
  strokeColor?: Color
  hideBorder?: boolean
  width?: string
  height?: string
  onClick?: () => void
}

const CloseIcon = ({
  color,
  hideBorder,
  onClick,
  strokeColor,
  width = "28px",
  height = "28px",
}: Props): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={
        // TODO: Refactor to only use V2
        (color && color in Color
          ? getTextColorClassV2(color as Color)
          : getTextColorV1Class(color as Storyblok.ColorV1),
        onClick && "cursor-pointer")
      }
      onClick={onClick}
    >
      <g
        id="Page-1"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        className={getStrokeColorClass(strokeColor as Color)}
      >
        <g id="hover" transform="translate(-1115.000000, -138.000000)">
          <g id="Group-27" transform="translate(1117.000000, 140.000000)">
            {!hideBorder ? (
              <circle strokeWidth="2.08695652" cx="12" cy="12" r="12"></circle>
            ) : null}
            <g id="Group-26" strokeLinecap="round">
              <g transform="translate(7.304348, 7.304348)">
                <line
                  x1="0.213438735"
                  y1="0.223602484"
                  x2="9.17786561"
                  y2="9.16770186"
                  strokeWidth="2.60869565"
                ></line>
                <line
                  x1="0.213438735"
                  y1="0.223602484"
                  x2="9.17786561"
                  y2="9.16770186"
                  strokeWidth="2.60869565"
                  transform="translate(4.695652, 4.695652) scale(-1, 1) translate(-4.695652, -4.695652) "
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CloseIcon
