import React from "react"

type Props = {
  className?: string
  svgClassName: string
}

const SupportIcon = ({ svgClassName, className }: Props): JSX.Element => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#ffffff"
        xmlns="http://www.w3.org/2000/svg"
        className={svgClassName}
      >
        <path
          d="M1 21.0001V21.0001C1 19.0011 2.62 17.3811 4.619 17.3811H8.238C10.237 17.3811 11.857 19.0011 11.857 21.0001V21.0001"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.66812 9.2606C9.90491 10.4974 9.90491 12.5026 8.66812 13.7394C7.43133 14.9762 5.4261 14.9762 4.18931 13.7394C2.95252 12.5026 2.95252 10.4974 4.18931 9.2606C5.4261 8.02381 7.43133 8.02381 8.66812 9.2606"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.0954 13.808C20.8034 13.808 22.9994 11.612 22.9994 8.904C22.9994 6.196 20.8044 4 18.0954 4C15.3864 4 13.1914 6.196 13.1914 8.904"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.1025 13.8131C17.3645 13.8131 16.6655 13.6501 16.0375 13.3591L13.0195 14.0001L13.6505 10.9761C13.3575 10.3461 13.1935 9.64405 13.1935 8.90405"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8127 8.96464C15.8322 8.98417 15.8322 9.01583 15.8127 9.03536C15.7932 9.05488 15.7615 9.05488 15.742 9.03536C15.7225 9.01583 15.7225 8.98417 15.742 8.96464C15.7615 8.94512 15.7932 8.94512 15.8127 8.96464"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.0627 8.96464C18.0822 8.98417 18.0822 9.01583 18.0627 9.03536C18.0432 9.05488 18.0115 9.05488 17.992 9.03536C17.9725 9.01583 17.9725 8.98417 17.992 8.96464C18.0115 8.94512 18.0432 8.94512 18.0627 8.96464"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3127 8.96464C20.3322 8.98417 20.3322 9.01583 20.3127 9.03536C20.2932 9.05488 20.2615 9.05488 20.242 9.03536C20.2225 9.01583 20.2225 8.98417 20.242 8.96464C20.2615 8.94512 20.2932 8.94512 20.3127 8.96464"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default SupportIcon
