import { useStaticQuery, graphql } from "gatsby"
import { SbGatsbyStory } from "gatsby-source-storyblok"
import React, { FC, useLayoutEffect } from "react"

import Footer from "./Footer"
import ToastNotification from "./V2/Banners/ToastNotification"
import NavigationV2 from "./V2/Navigation"
import "aos/dist/aos.css"
import "./layout.css"

import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"
import { mapNavigationMainLinks } from "@utils/V2/storyblok"

interface Props {
  children: any
}
interface QueryResult {
  footer: Storyblok.Page & SbGatsbyStory
  socialMedia: Storyblok.Page & SbGatsbyStory
}

const ToastBanner: FC<{
  content: Array<Storyblok.ToastNotificationBanner>
}> = ({ content }) => {
  return content.map((toastItem, index) => (
    <ToastNotification
      key={index}
      backgroundColor={toastItem.backgroundColor}
      textColor={toastItem.textColor}
      text={toastItem.text}
      icon={toastItem.icon.filename}
      ctaLink={getUrlFromStoryblokLink(toastItem.ctaLink)}
      ctaText={toastItem.ctaText}
    />
  ))
}

const Layout: React.FC<Props> = ({ children }) => {
  // const [isBrowserSupported, setIsBrowserSupported] = useState<boolean>(false)

  const queryResult: QueryResult = useStaticQuery(graphql`
    query StaticData {
      footer {
        uuid
        full_slug
        content
      }

      socialMedia {
        uuid
        full_slug
        content
      }
    }
  `)

  /* NOTE: A page can return navigation as a part of its story content or
     a page query can include a query of the default site header. The following
     logic prioritises custom navigation set from a page's story, then checks to
     see if a query of the default header was added, and finally sets headerData
     to undefined if no header information can be found. The header will not
     render in this case.
   */
  const pageData = children?.[children.length - 1]?.props?.data

  let headerData = pageData?.story?.content
  if (typeof headerData === "string") {
    headerData = JSON.parse(headerData)
  }

  const toastNotificationContent = headerData?.toastNotificationContent

  // This is to cater for the 404 page query
  const mode = headerData?.navigationMode ? headerData.navigationMode : "dark"

  const headerContent = headerData
    ? headerData.navigation
    : // This is to cater for the 404 page query
    pageData?.headerV2?.content
    ? { content: JSON.parse(pageData.headerV2.content) }
    : undefined

  // TODO: re-enable this when we have a better way to detect supported browsers
  // see: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#rendering_engine_version
  // basically, checking only for user agent string is not a reliable way, and should
  // be avoided. It is also quite difficult to differentiate between webview and browser,
  // and is generaly not recommended. Also, browsers these days can kinda spoof the user
  // agent, which further makes it kinda irrelevant. The recommended solution is to detect
  // specific features, as that can be more reliable.
  //
  // const renderBanner = () => {
  //   return (
  //     <div className="bg-orange-v2 text-white-v2 w-full fixed left-0 top-0 py-8-v2 px-16-v2 z-50 flex justify-between items-center gap-4-v2">
  //       <small>
  //         Your browser is not fully supported. Please consider upgrading to a
  //         modern browser for a better experience.
  //       </small>

  //       <small
  //         className="cursor-pointer hover:opacity-70"
  //         onClick={() => setIsBrowserSupported(true)}
  //       >
  //         <CloseIcon />
  //       </small>
  //     </div>
  //   )
  // }
  //
  // useLayoutEffect(() => {
  //   if ("requestAnimationFrame" in window && "cancelAnimationFrame" in window) {
  //     const ua = window.navigator.userAgent
  //     const supportedBrowsers = ["Firefox", "Chrome", "Safari"]

  //     for (const browser of supportedBrowsers) {
  //       if (ua.indexOf(browser) > -1) {
  //         setIsBrowserSupported(true)
  //         return
  //       }
  //     }
  //   }

  //   setIsBrowserSupported(false)
  // }, [])

  useLayoutEffect(() => {
    import("aos").then(({ default: AOS }) => {
      AOS.init({ once: true, offset: 120, duration: 500, easing: "ease" })
    })
  }, [])

  return (
    <>
      {toastNotificationContent?.length > 0 && (
        <ToastBanner content={toastNotificationContent} />
      )}
      {headerContent ? (
        <div id="navigation">
          <NavigationV2
            mode={mode}
            // TODO: Refactor this to typecast as variable declaration and to compute mainLinks on component level
            mainLinks={mapNavigationMainLinks(
              (headerContent.content as Storyblok.Navigation).mainLinks
            )}
            primaryButtonText={headerContent.content.primaryCTAText}
            primaryButtonLink={getUrlFromStoryblokLink(
              headerContent.content.primaryCTALink
            )}
            secondaryButtonText={headerContent.content.secondaryCTAText}
            secondaryButtonLink={getUrlFromStoryblokLink(
              headerContent.content.secondaryCTALink
            )}
            mobilePrimaryButtonText={headerContent.content.mobilePrimaryCTAText}
            mobilePrimaryButtonLink={
              linkIsNotNull(headerContent.content.mobilePrimaryCTALink)
                ? getUrlFromStoryblokLink(
                    headerContent.content.mobilePrimaryCTALink
                  )
                : undefined
            }
            isBannerContents={toastNotificationContent?.length ? true : false}
          />
        </div>
      ) : null}
      <main>{children}</main>

      {!headerData?.hide_footer ? (
        <Footer
          footer={queryResult.footer}
          socialMedia={queryResult.socialMedia}
        />
      ) : null}
    </>
  )
}

export default Layout
