import React, { createContext, useState } from "react"

type Context = {
  isMobileMenuOpen: boolean
  setIsMobileMenuOpen: (isOpen: boolean) => void
}

const emptyFn = (): void | Promise<void> => {
  console.warn(`NavigationV2Context not ready!`)
}

export const defaultContext: Context = {
  isMobileMenuOpen: false,
  setIsMobileMenuOpen: emptyFn,
}

export const NavigationV2Context = createContext(defaultContext)

type Props = {
  children: React.ReactNode
}

export const NavigationV2Provider = ({ children }: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(
    defaultContext.isMobileMenuOpen
  )

  const values = {
    isMobileMenuOpen,
    setIsMobileMenuOpen,
  }

  return (
    <NavigationV2Context.Provider value={values}>
      {children}
    </NavigationV2Context.Provider>
  )
}
