export type Dimensions = {
  width: string | undefined
  height: string | undefined
}

// MARK: Used in V2
export const getImageDimensionsFromUrl = (url: string): Dimensions => {
  //matches the format numberxnumber/ using a positive lookahead assertion
  //lookbehind assertions are not supported in safari at the time of writing
  const retrievedDimension = url.match(/[0-9]+x[0-9]+(?=\/)/)?.[0]

  const dimensions = {
    width: retrievedDimension?.split("x")[0],
    height: retrievedDimension?.split("x")[1],
  }

  return dimensions
}
