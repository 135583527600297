import { useStaticQuery, graphql } from "gatsby"
import { useCallback } from "react"

import { consolidateProduct } from "../utils/shop"

import { useGetProductBySku } from "@hooks/products"

// MARK: Used in V2 start
export const storyblokUrlToInternalUrl = (url: string): string => {
  return url?.replace(process.env.GATSBY_STORYBLOK_ROOT || "", "") ?? ""
}

export const getUrlFromStoryblokLink = (link: Storyblok.Link): string => {
  if (link.linktype === "email") {
    return `mailto:${link.email}`
  }

  const urlReference = link.url || link.cached_url
  if (
    urlReference.includes("http") ||
    urlReference.includes("tel:") ||
    urlReference.startsWith("#")
  ) {
    return urlReference
  }
  return (
    // TODO: Check if prefix path still needed with V2 implementation
    `${process.env.GATSBY_PREFIX_PATH || ""}/${storyblokUrlToInternalUrl(
      urlReference
    )}${urlReference && urlReference.slice(-1) !== "/" ? "/" : ""}` || "/"
  )
}

export const linkIsNotNull = (link: Storyblok.Link | undefined): boolean => {
  return !!(link?.cached_url || link?.url || link?.email)
}
// MARK: Used in V2 end

export const linkIsExternalUrl = (link: Storyblok.Link | string): boolean => {
  const urlReference =
    typeof link === "string" ? link : link?.url || link?.cached_url
  const isEmail =
    (typeof link === "string" && link.includes("mailto:")) ||
    (typeof link !== "string" && link?.linktype === "email")

  return (
    isEmail || urlReference?.includes("http") || urlReference?.includes("tel:")
  )
}

export const isEditorWindow = (): boolean => {
  return window.location.pathname.includes("/editor/")
}

export const parseStoryblokDate = (date?: string): Date | null => {
  return date ? new Date(date.replace(" ", "T")) : null
}

// MARK: Used in V2 start
interface storyBlokProduct {
  uuid: string
  content: string
  full_slug: string
}

export const useGetStoryblokProduct = (): ((
  id: string,
  useSlug?: boolean
) => Storyblok.ProductConfig | null) => {
  const data = useStaticQuery(graphql`
    query StoryblokProducts {
      allStoryblokEntry(
        filter: { full_slug: { regex: "/settings/products/" } }
      ) {
        edges {
          node {
            uuid
            content
            full_slug
          }
        }
      }
    }
  `)

  const getStoryblokProduct = useCallback(
    (id: string, useSlug = false) => {
      const product = data.allStoryblokEntry.edges.find(
        (product: { node: storyBlokProduct }) => {
          if (useSlug) {
            return product.node.full_slug == id
          } else {
            return product.node.uuid == id
          }
        }
      )

      if (product) {
        return JSON.parse(product.node.content)
      }

      return null
    },
    [data]
  )

  return getStoryblokProduct
}

export const useGetProductBySlug = (): ((
  slug: string
) => Storyblok.ProductConfig | YocoCom.ConsolidatedProduct | null) => {
  const getStoryBlokProduct = useGetStoryblokProduct()
  const getSdkProduct = useGetProductBySku()

  const getProductBySlug = useCallback(
    (slug: string) => {
      const storyblokProduct = getStoryBlokProduct(slug, true)

      if (storyblokProduct) {
        const sdkProduct = getSdkProduct(storyblokProduct.sku)

        if (sdkProduct) {
          return consolidateProduct(
            sdkProduct.id.toString(),
            1,
            sdkProduct,
            storyblokProduct
          )
        }

        return storyblokProduct
      }

      return null
    },
    [getSdkProduct, getStoryBlokProduct]
  )

  return getProductBySlug
}
// MARK: Used in V2 end
