import classNames from "classnames"
import React from "react"

import { Color } from "../../../constants/V2/color"

import { getBackgroundColorClass } from "@utils/V2/color"

type Props = {
  color: Color
  mobileMenuOpen: boolean
  onToggle: (isActive: boolean) => void
}

export default function HamburgerIcon({
  color,
  mobileMenuOpen,
  onToggle,
}: Props) {
  function handleToggle() {
    if (onToggle) {
      onToggle(!mobileMenuOpen)
    }
  }

  return (
    <button
      className="block"
      type="button"
      aria-label="Menu"
      onClick={handleToggle}
    >
      <span
        className={classNames(
          "block w-5 h-[2.5px] mb-[3.25px] origin-top-left transition-all duration-200",
          getBackgroundColorClass(color),
          { "rotate-45": mobileMenuOpen }
        )}
      ></span>
      <span
        className={classNames(
          "block w-5 h-[2.5px] mb-[3.25px] transition-all duration-200",
          getBackgroundColorClass(color),
          { "opacity-0 -translate-x-full": mobileMenuOpen }
        )}
      ></span>
      <span
        className={classNames(
          "block w-5 h-[2.5px] mb-[3.25px] origin-bottom-left transition-all duration-200",
          getBackgroundColorClass(color),
          { "-rotate-45": mobileMenuOpen }
        )}
      ></span>
    </button>
  )
}
