import classNames from "classnames"
import React, { createElement } from "react"

import { Color } from "../../../constants/V2/color"
import { getTextColorClass } from "../../../utils/V2/color"

export type TypographySize =
  | "display1"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "lead-lg"
  | "lead-md"
  | "lead-sm"
  | "body-lg"
  | "body-md"
  | "body-sm"
  | "subscript-lg"
  | "subscript-md"
  | "subscript-sm"

type Element = "p" | "h1" | "h2" | "h3" | "h4" | "h5"

type Props = {
  text: string
  size: TypographySize
  font: "grotesk" | "grotesk-25" | "grotesk-10"
  weight: "book" | "medium"
  color: Color
  element?: Element
  className?: string
  nonResponsive?: boolean
  onClick?: () => any
  leadingNone?: boolean
}

const Typography: React.FC<Props> = ({
  text,
  size,
  font,
  weight,
  color,
  element = "p",
  className,
  nonResponsive = false,
  onClick,
  leadingNone = false,
  ...props
}) => {
  /*
    Note: Since this component is the only way in which text should be added to the site,
    both font size and family can be resolved locally here instead of being exported globally.
  */
  const getFontSizeClass = () => {
    switch (size) {
      case "display1":
        return nonResponsive
          ? "text-display1-v2"
          : "text-responsive-display1-v2"
      case "h1":
        return nonResponsive ? "text-h1-v2" : "text-responsive-h1-v2"
      case "h2":
        return nonResponsive ? "text-h2-v2" : "text-responsive-h2-v2"
      case "h3":
        return nonResponsive ? "text-h3-v2" : "text-responsive-h3-v2"
      case "h4":
        return nonResponsive ? "text-h4-v2" : "text-responsive-h4-v2"
      case "h5":
        return nonResponsive ? "text-h5-v2" : "text-responsive-h5-v2"
      case "lead-lg":
        return nonResponsive ? "text-lead-lg-v2" : "text-responsive-lead-lg-v2"
      case "lead-md":
        return nonResponsive ? "text-lead-md-v2" : "text-responsive-lead-md-v2"
      case "lead-sm":
        return "text-responsive-lead-sm-v2"
      case "body-lg":
        return nonResponsive ? "text-body-lg-v2" : "text-responsive-body-lg-v2"
      case "body-md":
        return nonResponsive ? "text-body-md-v2" : "text-responsive-body-md-v2"
      case "body-sm":
        return "text-responsive-body-sm-v2"
      case "subscript-lg":
        return nonResponsive
          ? "text-subscript-lg-v2"
          : "text-responsive-subscript-lg-v2"
      case "subscript-md":
        return nonResponsive
          ? "text-subscript-md-v2"
          : "text-responsive-subscript-md-v2"
      case "subscript-sm":
        return "text-responsive-subscript-sm-v2"
      default:
        return ""
    }
  }

  const getFontFamilyClass = () => {
    switch (font) {
      case "grotesk":
        return "font-grotesk"
      case "grotesk-25":
        return "font-grotesk-25"
      case "grotesk-10":
        return "font-grotesk-10"
      default:
        return ""
    }
  }

  return createElement(
    element,
    {
      className: classNames(
        getFontSizeClass(),
        getFontFamilyClass(),
        getTextColorClass(color),
        weight === "book" ? "font-normal" : "font-medium",
        { "!leading-none": leadingNone },
        className
      ),
      onClick,
      ...props,
    },
    text
  )
}

export default Typography
